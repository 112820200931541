/**
 * Сервис с общими методами, которые могут использоваться где угодно по необходимости
 */
export default class GeneralServices {
  /**
   * Форматирование даты из timestamp в DD.MM.YYYY
   *
   * @param inputDate
   * @return {String}
   */
  static formatDate(inputDate) {
    return new Date(inputDate).toLocaleString().slice(0, 10);
  }
}
